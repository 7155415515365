import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'next/navigation';

const useLanguageSetting = ({
  isZhEn,
  rnLanguageIsFirst,
}: {
  isZhEn: boolean;
  rnLanguageIsFirst: boolean;
}) => {
  const searchParams = useSearchParams();
  const rnLanguage = searchParams.get('lang');

  const { language } = useRecoilValue(MyInfoState);
  const { i18n } = useTranslation();

  useEffect(() => {
    let settingLanguage = '';

    if (language === 'en') {
      settingLanguage = 'en';
    } else if (language === 'zh') {
      settingLanguage = isZhEn ? 'en' : 'zh';
    }

    //RN 외국어모드로 오픈시 최우선권
    if (rnLanguageIsFirst && rnLanguage === 'en') {
      settingLanguage = 'en';
    }

    if (settingLanguage) {
      i18n.changeLanguage(language);
    }
  }, [language, rnLanguage, isZhEn, rnLanguageIsFirst]);
};

export default useLanguageSetting;
