import { useTranslation } from 'react-i18next';
import useLanguageSetting from '@/hooks/useLanguageSetting';
import { Box, SxProps } from '@mui/material';

const ErrorNotFoundPage = ({ sx }: { sx?: SxProps }) => {
  useLanguageSetting({ isZhEn: true, rnLanguageIsFirst: false });
  const { i18n } = useTranslation();
  const isKo = i18n.language === 'ko';

  const title = isKo ? '요청하신 페이지를 찾을 수 없습니다.' : 'Page Not Found';
  const subTitle = isKo
    ? '페이지의 주소가 이동, 삭제되었거나 잘못 입력되었을 수 있습니다.\n입력하신 주소를 다시 한번 확인해 주세요.'
    : 'We couldn’t find the page you’re looking for.\nIt may have been moved, deleted, or the URL might be incorrect.\nPlease check the URL and try again.';

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: `url('/images/error_page/background_image@3x.png')`,
        backgroundImage: `image-set(
        url('/images/error_page/background_image.png') 1x,
        url('/images/error_page/background_image@2x.png') 2x,
        url('/images/error_page/background_image@3x.png') 3x
        )`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        padding: '30px',
        textAlign: 'center',
        ...sx,
      }}
    >
      <Box sx={{ fontSize: '20px', fontWeight: '700', lineHeight: '28px' }}>
        {title}
      </Box>
      <div
        style={{
          fontSize: '14px',
          lineHeight: '24px',
          whiteSpace: 'pre-line',
        }}
      >
        {subTitle}
      </div>
    </Box>
  );
};

export default ErrorNotFoundPage;
